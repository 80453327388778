<template>
    <div>
      <h3>Utenti BO</h3>
       <v-data-table
            :loading="loading"
            :loading-text="$t('commons.loading')"
            :headers="headers"
            :items="users"
            :items-per-page="pagination.per_page"
            :hide-default-footer="true"
            class="elevation-0 clickable border-all"
            disable-pagination
       >

            <template v-slot:item.avatar="{ item }">
              <v-avatar size="50" class="my-2">
                <v-img
                    :src="item.avatar"
                    max-width="50"
                    contain
                />
              </v-avatar>

            </template>

            <template v-slot:item.scopes="{ item }">
              <span v-for="scope in item.scopes" :key="scope">{{scope}}</span>
            </template>


        </v-data-table>

        <v-divider></v-divider>

        <div class="text-center py-3">
            <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                :total-visible="5"
                @input="getUsers()"
                :disabled="isLoading"
            ></v-pagination>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "UserList",

        data() {
            return {
                userFilter: '',
                filterMenu: false,
                filters: {
                    exact: {},
                    like: {}
                },
                headers: [
                  { text: this.$t('commons.fields.id'), value: 'id', sortable: false, width: '5%'},
                  { text: this.$t('users.fields.email'), value: 'email', sortable: false},
                  { text: this.$t('users.fields.scopes'), value: 'scopes', sortable: false},
                ]
            }
        },

        mounted() {
            this.getUsers()
        },

        methods: {
            getUsers() {
                this.$store.dispatch('user/fetchBoUsers', this.filters)
            },
        },

        computed: {
          ...mapGetters({
              isLoading: 'user/loadingState',
              users: 'user/getBoUsers',
              pagination: 'user/getPagination'
          }),

          loading: {
              get() {
                  return this.isLoading
              },
              set(v) {
                  this.$store.dispatch('user/setLoadingStatus', v)
              }
          },
        }
    }
</script>

<style scoped>

</style>
