<template>
<div>
      <p>{{$t('users.create.store.intro')}}</p>
      <v-row>
        <v-col cols="4">
          <v-select
              v-model="city"
              :label="$t('users.create.store.fields.city')"
              :items="cities"
              :rules="requiredRules"
              :required="true"
              outlined
              dense
              @input="getStores"
          ></v-select>
        </v-col>
        <v-col cols="8">
          <v-select
              v-model="store_code"
              :label="$t('users.create.store.fields.store')"
              :items="stores"
              :rules="requiredRules"
              :required="true"
              item-text="name"
              item-value="store_code"
              outlined
              dense
              :disabled="!city || !stores"
              @input="emitStoreValue"
          ></v-select>
        </v-col>
      </v-row>
</div>
</template>

<script>

import {mapGetters} from "vuex";
import {validationRules} from "@/mixins/validationRules";

export default {
  name: "SearchStore",

  mixins: [validationRules],

  props: {
    field: {
      type: String,
      default: 'store_code'
    }
  },

  data() {
    return {
      city: '',
      store_code: '',
      result: null,
      searchDialog: false,
      search: ''
    }
  },

  mounted() {
    this.$store.dispatch('store/fetchStoreCities')
  },

  computed: {
    ...mapGetters({
      cities: 'store/getCities',
      stores: 'store/getStores',
      isLoading: 'store/loadingState'
    }),
  },

  methods: {
    getStores() {
      this.$store.dispatch('store/search', this.city)
    },

    emitStoreValue(ev) {
      // prendo l'indice dell'array a cui corrisponde lo store selezionato
      let store_idx = this.stores.map(store => store.store_code).indexOf(ev)

      // emetto il valore scelto in base al prosp `return`
      this.$emit('update:store_code', this.stores[store_idx][this.field])
    }
  }
}
</script>

<style scoped>

</style>