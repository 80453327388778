<template>
  <v-sheet class="pa-5">

    <user-bo-form></user-bo-form>
    <user-bo-list></user-bo-list>

    <v-divider class="py-15"></v-divider>

    <bo-invite-list></bo-invite-list>

  </v-sheet>

</template>

<script>
import UserList from "@/components/admin/UserList";
import BoInviteList from "@/components/admin/BoInviteList";
import Form from "@/components/admin/Form";

export default {

  name: "List",
  components: {
    'user-bo-form': Form,
    'user-bo-list': UserList,
    'bo-invite-list': BoInviteList
  },
  data() {
    return {
      search: '',
      openFilters: false,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
