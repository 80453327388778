<template>
     <v-row>
          <v-col cols="12">
               <h1>Overlays</h1>
          </v-col>
          <v-col cols="12">
               <ListOverlays/>
          </v-col>
     </v-row>
</template>

<script>
import ListOverlays from "@/components/overlays/List"
export default {
     components:{
          ListOverlays
     }
}
</script>