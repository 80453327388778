var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"elevation-0 clickable",attrs:{"loading":_vm.isLoading,"loading-text":_vm.$t('commons.loading'),"headers":_vm.headers,"items":_vm.overlays,"items-per-page":_vm.meta.per_page,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.title))]),_c('br'),_c('small',[_vm._v(_vm._s(item.text))])]}},{key:"item.skippable",fn:function(ref){
var item = ref.item;
return [((item.skippable))?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e(),(!(item.skippable))?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")]):_vm._e()]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [((item.is_active))?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-radioactive")]):_vm._e(),(!(item.is_active))?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-radioactive-off")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.button)?_c('v-btn',{attrs:{"color":"info","outlined":"","x-small":""},on:{"click":function($event){return _vm.myalert(item)}}},[_vm._v(" "+_vm._s(item.button_text)+" ")]):_vm._e()]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('DialogFullScreen',{attrs:{"btnIcon":"mdi-pencil","button":_vm.$t('commons.actions.update'),"title":_vm.$t('commons.actions.update')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onClose = ref.onClose;
return _c('FormOverlay',{attrs:{"overlay":item},on:{"close":onClose}})}}],null,true)})]}}])}),_c('v-divider'),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"text-center py-3"},[_c('v-pagination',{attrs:{"length":_vm.meta.last_page,"total-visible":5,"disabled":_vm.isLoading},on:{"input":function($event){return _vm.get()}},model:{value:(_vm.meta.current_page),callback:function ($$v) {_vm.$set(_vm.meta, "current_page", $$v)},expression:"meta.current_page"}})],1)]),_c('v-divider'),_c('v-card-text',[_c('DialogFullScreen',{attrs:{"button":_vm.$t('commons.actions.new')+' '+_vm.$t('overlay'),"title":_vm.$t('commons.actions.new')+' '+_vm.$t('overlay')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onClose = ref.onClose;
return _c('FormOverlay',{attrs:{"new":""},on:{"close":onClose}})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }