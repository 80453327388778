<template>
     <v-row>
          <v-col cols="12">
               <h1>Exports</h1>
          </v-col>
          <v-col cols="12">
               <ExportsList/>
          </v-col>
     </v-row>
</template>

<script>
import ExportsList from "@/components/exports/List"
export default {
     components:{
          ExportsList
     }
}
</script>