<template>
     <v-card>
          <v-data-table
        :loading="isLoading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="modifiers"
        :items-per-page="meta.per_page"
        :hide-default-footer="true"
        class="elevation-0 clickable"
         @click:row="edit"
        disable-pagination
    >

      <template v-slot:item.is_active="{ item }">
        <v-icon color="success" v-if="(item.is_active)">mdi-radioactive</v-icon>
        <v-icon color="error" v-if="!(item.is_active)">mdi-radioactive-off</v-icon>
      </template>
      <template v-slot:item.has_products="{ item }">
        <v-icon color="success" v-if="(item.has_products)">mdi-check</v-icon>
        <v-icon color="error" v-if="!(item.has_products)">mdi-close</v-icon>
      </template>
      <template v-slot:item.type="{ item }">
        <b>{{item.type}} {{item.value}}</b>
      </template>
      <template v-slot:item.edit="{ item }">
          <DialogFullScreen btnIcon="mdi-pencil" :button="$t('commons.actions.update')" :title="$t('commons.actions.update')">
               <FromModifier :modifier="item" @close="onClose" slot-scope="{ onClose }" />
          </DialogFullScreen>
      </template>
    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
          v-model="meta.current_page"
          :length="meta.last_page"
          :total-visible="5"
          @input="get()"
          :disabled="isLoading"
      ></v-pagination>
    </div>
     <v-card-text>
          
          <DialogFullScreen :button="$t('commons.actions.new')+' '+$t('modifier')" :title="$t('commons.actions.new')+' '+$t('modifier')">
               <FromModifier @close="onClose" slot-scope="{ onClose }" new />
          </DialogFullScreen>                        
     </v-card-text>
     </v-card>
</template>


<script>
import {mapGetters} from "vuex";
import FromModifier from "./FormModifier";
import DialogFullScreen from '@/components/commons/DialogFullScreen'
export default {
     components:{FromModifier,DialogFullScreen},
     computed: {
          ...mapGetters({
               isLoading: 'adminBillModifiers/loading',
               modifiers: 'adminBillModifiers/modifiers',
               modifier: 'adminBillModifiers/modifier',
               errors: 'adminBillModifiers/errors',
               meta: 'adminBillModifiers/meta'
          }),
     },
     data: () => ({
          filters: {wheres:[]},
          viewdialog: false,
          headers:[
               {text:'#', value: 'id', sortable: false, width: "5%", align: 'center'},
               {text:'country', value: 'country', sortable: false, width: "5%", align: 'center'},
               {text:'description', value: 'description', sortable: false, width: "35%", align: 'start'},
               {text:'active', value: 'is_active', sortable: false, width: "5%", align: 'center'},
               {text:'type', value: 'type', sortable: false, width: "5%", align: 'center'},
               {text:'products', value: 'has_products', sortable: false, width: "5%", align: 'center'},
               {text:'edit', value: 'edit', sortable: false, width: "5%", align: 'center'},
          ],
     }),
     mounted(){
          if(this.modifiers.length < 1){
               this.get()
          }
     },
     methods:{
          async get(){
               this.$store.dispatch('adminBillModifiers/list',this.filters)
          },
          edit(item){
               console.log(item)
               this.$store.commit('adminBillModifiers/setModifier',item)
               this.viewdialog = true
          }
     }
}
</script>
