import apiClient from '../../plugins/apiClient'
const querystring = require('querystring');

export default {
    namespaced: true,
    state: {
        apiFails: [],
        apiFail: {},
        pagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
        isWorking: false,
        isLoading: false
    },

    getters: {
        isWorking: state => state.isWorking,
        loadingState: state => state.isLoading,
        getList: state => state.apiFails,
        details: state => state.apiFail,
        getPagination: state => state.pagination
    },

    actions: {
        fetchList({commit, state}, payload) {

            console.log('STORE API FAIL | ACTIONS | list fails payload ', payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)
                let qs = ''
                if (payload) {
                    qs = '&' + querystring.stringify(payload);
                }

                apiClient.get('api-fails?page=' + state.pagination.current + qs)
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('list', resp.data.data);
                        commit('pagination', resp.data.meta)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        reject(err);
                    });
            })
        }
    }
    ,
    mutations:
        {
            list(state, payload) {
                state.apiFails = payload
            }
            ,

            loading(state, payload) {
                state.isLoading = payload
            }
            ,

            pagination(state, payload) {
                state.pagination.current = payload.current_page
                state.pagination.total = payload.last_page
                state.pagination.per_page = payload.par_page
            }
            ,
        }
}