<template>
     <v-row>
          <v-col cols="12">
               <v-progress-linear :indeterminate="isLoading"></v-progress-linear>
          </v-col>
          <v-col cols="12" v-if="message">
               <v-alert type="error" v-if="Object.keys(errors).length">
                    {{message}}<br>
                    <ul>
                    <li v-for="(err,field) in errors" :key="field">
                         <b>{{field}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}</span>
                    </li>  
                    </ul>
               </v-alert>
               <v-alert type="primary" v-else>
                    {{message}}
               </v-alert>
          </v-col>
          <v-col cols="12">
                <countries
                    :country.sync="item.country"
                    :label="$t('country')"
                    :required-rules="requiredRules"
                ></countries>
          </v-col>
          <v-col cols="12">
               <v-text-field
                    v-model="item.title"
                    :label="$t('title')"
                    required
                    outlined
                    :rules="requiredRules"
                    dense
               ></v-text-field>
               <v-text-field
                    v-model="item.description"
                    :label="$t('description')"
                    required
                    outlined
                    :rules="requiredRules"
                    dense
               ></v-text-field>
               <v-switch 
                    :label="$t('scheduled')" 
                    v-model="item.scheduled">
               </v-switch>
          </v-col>
          <v-col cols="12">
               <v-textarea
                    outlined
                    name="input-7-4"
                    :label="$t('query')"
                    v-model="item.query"
               ></v-textarea>
          </v-col>
          <v-col cols="12">
               <v-btn color="primary" :loading="isLoading" block @click="save">SAVE</v-btn>
          </v-col>
          <v-col cols="12">
               <json-debug :objs="[{item:item}]"></json-debug>
          </v-col>
     </v-row>
</template>

<script>
import Countries from "@/components/form/Countries";
import Datepicker from "@/components/form/Datepicker";
import {mapGetters} from "vuex";

export default {
     components:{
         'countries': Countries,
         'datepicker': Datepicker,
     },
     computed: {
          ...mapGetters({
               isLoading: 'exports/loading',
               errors: 'exports/errors',
               message: 'exports/message',
          }),
     },
     props: {
          item:{
               type: Object,
               default: () => ({
                    "title": null,
                    "description": null,
                    "query": null,
                    "scheduled": 0,
                    "country": null
               })
          }
     },
     data: () => ({
          requiredRules: [ v => (v !== '' || !!v) || 'required', ],
     }),
     methods:{
          async save(){
               if(this.item.id){
                    this.$store.dispatch('exports/update',this.item)
                    .then((response) => {
                         console.log(response)
                         this.$emit('close')
                         this.$store.dispatch('exports/list')
                    })
               }else{
                    this.$store.dispatch('exports/create',this.item)
                    .then((response) => {
                         console.log(response)
                         this.$emit('close')
                         this.$store.dispatch('exports/list')
                    })
               }
          }
     }
}
</script>
