<template>
     <v-card>
          <v-data-table
               :loading="isLoading"
               :loading-text="$t('commons.loading')"
               :headers="headers"
               :items="overlays"
               :items-per-page="meta.per_page"
               :hide-default-footer="true"
               class="elevation-0 clickable"
               disable-pagination
          >

      <template v-slot:item.title="{ item }">
           <b>{{item.title}}</b><br>
           <small>{{item.text}}</small>
      </template>
      <template v-slot:item.skippable="{ item }">
        <v-icon color="success" v-if="(item.skippable)">mdi-check</v-icon>
        <v-icon color="error" v-if="!(item.skippable)">mdi-close</v-icon>
      </template>
     <template v-slot:item.is_active="{ item }">
        <v-icon color="success" v-if="(item.is_active)">mdi-radioactive</v-icon>
        <v-icon color="error" v-if="!(item.is_active)">mdi-radioactive-off</v-icon>
      </template>
      <template v-slot:item.action="{ item }">
           <v-btn color="info" outlined x-small v-if="item.button" @click="myalert(item)">
               {{ item.button_text }}
           </v-btn>
      </template>
      <template v-slot:item.edit="{ item }">
          <DialogFullScreen btnIcon="mdi-pencil" :button="$t('commons.actions.update')" :title="$t('commons.actions.update')">
               <FormOverlay :overlay="item" @close="onClose" slot-scope="{ onClose }" />
          </DialogFullScreen>
      </template>
    </v-data-table>

    <v-divider></v-divider>

     <v-divider></v-divider>
     <v-card-text>
    <div class="text-center py-3">
      <v-pagination
          v-model="meta.current_page"
          :length="meta.last_page"
          :total-visible="5"
          @input="get()"
          :disabled="isLoading"
      ></v-pagination>
    </div>
     </v-card-text>
     <v-divider></v-divider>
     <v-card-text>
          <DialogFullScreen :button="$t('commons.actions.new')+' '+$t('overlay')" :title="$t('commons.actions.new')+' '+$t('overlay')">
               <FormOverlay @close="onClose" slot-scope="{ onClose }" new />
          </DialogFullScreen>                        
     </v-card-text>
     </v-card>
</template>


<script>
import {mapGetters} from "vuex";
import FormOverlay from "./FormOverlay";
import DialogFullScreen from '@/components/commons/DialogFullScreen'
export default {
     components:{FormOverlay,DialogFullScreen},
     computed: {
          ...mapGetters({
               isLoading: 'overlays/loading',
               overlays: 'overlays/overlays',
               overlay: 'overlays/overlay',
               errors: 'overlays/errors',
               meta: 'overlays/meta'
          }),
     },
     data: () => ({
          filters: {wheres:[]},
          viewdialog: false,
          headers:[
               {text:'#', value: 'id', sortable: false, width: "5%", align: 'center'},
               {text:'country', value: 'country', sortable: false, width: "5%", align: 'center'},
               {text:'title', value: 'title', sortable: false, width: "35%", align: 'start'},
               {text:'is_active', value: 'is_active', sortable: false, width: "5%", align: 'center'},
               {text:'complete_on', value: 'complete_on', sortable: false, width: "5%", align: 'center'},
               {text:'skippable', value: 'skippable', sortable: false, width: "5%", align: 'center'},
               {text:'action', value: 'action', sortable: false, width: "10%", align: 'center'},
               {text:'edit', value: 'edit', sortable: false, width: "5%", align: 'center'},
          ],
     }),
     mounted(){
          if(this.overlays.length < 1){
               this.get()
          }
     },
     methods:{
          async get(){
               this.$store.dispatch('overlays/list',this.filters)
          },
          edit(item){
               console.log(item)
               this.$store.commit('overlays/setoverlay',item)
               this.viewdialog = true
          },
          myalert(item){
               alert(item.button_target+'\n\n'+item.button_url)
          }
     }
}
</script>
