<template>
    <v-sheet class="pa-5">

        <list-api-fail></list-api-fail>

    </v-sheet>

</template>

<script>

    import ListApiFail from "@/components/apiFail/ListApiFail";
    import apiFail from "@/store/modules/apiFail";

    export default {

        name: "List",

        data () {
            return {
                search: '',
                openFilters: false,
            }
        },

        components: {
          ListApiFail
        },

      beforeMount() {
        if (!this.$store.hasModule('apiFail')) {
          this.$store.registerModule('apiFail', apiFail)
        }
      },
    }
</script>

<style lang="scss" scoped>
</style>
