<template>
     <v-row>
          <v-col cols="12">
               <v-progress-linear :indeterminate="isLoading"></v-progress-linear>
          </v-col>
          <v-col cols="12" v-if="message">
               <v-alert type="error" v-if="Object.keys(errors).length">
                    {{message}}<br>
                    <ul>
                    <li v-for="(err,field) in errors" :key="field">
                         <b>{{field}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}</span>
                    </li>  
                    </ul>
               </v-alert>
               <v-alert type="primary" v-else>
                    {{message}}
               </v-alert>
          </v-col>
          <v-col cols="12">
                <countries
                    :country.sync="overlay.country"
                    :label="$t('country')"
                    :required-rules="requiredRules"
                ></countries>
          </v-col>
          <v-col cols="12">
               <v-text-field
                    v-model="overlay.title"
                    :label="$t('title')"
                    required
                    outlined
                    :rules="requiredRules"
                    dense
               ></v-text-field>
               <v-text-field
                    v-model="overlay.text"
                    :label="$t('text')"
                    required
                    outlined
                    :rules="requiredRules"
                    dense
               ></v-text-field>
          </v-col>
          <v-col cols="4">
               <v-switch label="skippable" v-model="overlay.skippable"></v-switch>
          </v-col>
          <v-col cols="8">
               <v-select
                     :items="['app_rules','annual_confirm','update_app','first_open']"
                     v-model="overlay.complete_on"
                     outlined
                     dense
                     label="complete_on"
                ></v-select>
          </v-col>
          <v-col cols="4" >
               <v-switch label="hasbutton" v-model="overlay.button"></v-switch>
          </v-col>
          <v-col  cols="8" >
               <div v-if="overlay.button">
               <v-select
                     :items="['webview','app','browser',null]"
                     v-model="overlay.button_target"
                     outlined
                     dense
                     label="button_target"
                ></v-select>
               <v-text-field
                    v-model="overlay.button_text"
                    :label="$t('button_text')"
                    required
                    outlined
                    :rules="requiredRules"
                    dense
               ></v-text-field>
               <v-text-field
                    v-if="overlay.button_target != 'app'"
                    v-model="overlay.button_url"
                    :label="$t('button_url')"
                    required
                    outlined
                    :rules="requiredRules"
                    dense
               ></v-text-field>
               <v-select
                     v-if="overlay.button_target == 'app'"
                     :items="['missions','coupons','user-profile','boutiques','settings']"
                     v-model="overlay.button_url"
                     outlined
                     dense
                     label="type"
                ></v-select>
               </div>
          </v-col>
          
          <v-col cols="6">
               <datepicker
                    id="start"
                    :date.sync="overlay.start"
                    :label="$t('start')"
                    :max="overlay.end"
               ></datepicker>
          </v-col>
          <v-col cols="6">
               <datepicker
                    id="end"
                    :date.sync="overlay.end"
                    :label="$t('end')"
                    :min="overlay.start"
               ></datepicker>
          </v-col>
          <v-col cols="12">
               <v-btn color="primary" :loading="isLoading" block @click="save">SAVE</v-btn>
          </v-col>
          <v-col cols="12">
               <json-debug :objs="[{overlay:overlay}]"></json-debug>
          </v-col>
     </v-row>
</template>

<script>
import Countries from "@/components/form/Countries";
import Datepicker from "@/components/form/Datepicker";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
     components:{
         'countries': Countries,
         'datepicker': Datepicker,
     },
     computed: {
          ...mapGetters({
               isLoading: 'overlays/loading',
               errors: 'overlays/errors',
               message: 'overlays/message',
          }),
     },
     props: {
          overlay:{
               type: Object,
               default: () => ({
                    "skippable": null,
                    "priority": null,
                    "complete_on": null,
                    "title": null,
                    "text": null,
                    "button": null,
                    "button_target": null,
                    "button_text": null,
                    "button_url": null,
                    "start": null,
                    "end": null,
                    "country": null,
               })
          }
     },
     data: () => ({
          requiredRules: [ v => (v !== '' || !!v) || 'required', ],
     }),
     beforeMount(){
          this.overlay.start = (this.overlay.start) ? moment(this.overlay.start).toISOString().substr(0, 10) : moment().toISOString().substr(0, 10)
          this.overlay.end = (this.overlay.end) ? moment(this.overlay.end).toISOString().substr(0, 10) : moment().add(1,'days').toISOString().substr(0, 10)
     },
     methods:{
          async save(){
               if(this.overlay.id){
                    this.$store.dispatch('overlays/update',this.overlay)
                    .then((response) => {
                         console.log(response)
                         this.$emit('close')
                    })
               }else{
                    this.$store.dispatch('overlays/create',this.overlay)
                    .then((response) => {
                         console.log(response)
                         this.$emit('close')
                    })
               }
          }
     }
}
</script>
