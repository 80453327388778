<template>
  <v-dialog
      v-model="dialog"
      max-width="700px"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          class="mb-2"
          v-bind="attrs"
          v-on="on"
          :disabled="isLoading"
      >
        {{ $t('commons.actions.new') }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-container>

          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="user.email"
                  :rules="requiredRules"
                  :label="$t('users.fields.email')"
                  required
                  outlined
                  dense
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-select
                  v-model="user.scope"
                  :label="$t('users.fields.scope')"
                  :items="scopes"
                  item-value="value"
                  item-text="label"
                  :rules="requiredRules"
                  outlined
                  dense
              ></v-select>
            </v-col>

            <v-col cols="2">
              <languages
                  :language.sync="user.language"
                  :required-rules="requiredRules"
              ></languages>
            </v-col>
          </v-row>

          <search-store v-if="user.scope === 'store'"
                        :store_code.sync="user.store_code"
          ></search-store>

          <v-divider></v-divider>

          <v-row>
            <v-col>
              <dialog-buttons
                  :valid="valid"
                  :is-loading="isLoading"
                  @save="storeUser"
                  @close="close"
              ></dialog-buttons>
            </v-col>
          </v-row>

        </v-container>
      </v-form>

      <json-debug :objs="[{user: user}]"/>

    </v-card>

  </v-dialog>
</template>

<script>

import clonedeep from 'lodash.clonedeep'
import DialogButtons from "@/components/form/DialogButtons";
import {mapGetters} from "vuex";
import {EventBus} from "@/event-bus";
import {validationRules} from "@/mixins/validationRules";
import SearchStore from "@/components/commons/SearchStore";
import Languages from "@/components/form/Languages";

export default {
  name: "Form",

  components: {DialogButtons, SearchStore, Languages},

  mixins: [validationRules],

  data: () => ({
    user: {},
    defaultUser: {
      email: '',
      language: 'it',
      scope: '',
      store_code: ''
    },
    isEditing: false,
    dialog: false,
    sending: false,
    valid: false,
  }),

  mounted() {

    let self = this

    this.user = clonedeep(this.defaultUser)

    EventBus.$on('edit-user', function (user) {
      self.$store.dispatch('user/fetchBoUser', user.id)
          .then(() => {
            self.user = self.$store.getters['user/getBoUser']
          })
      self.isEditing = true
      self.dialog = true
    })
  },

  computed: {
    ...mapGetters({
      scopes: 'boInvite/getScopes',
      isLoading: 'boInvite/loadingState'
    }),
    formTitle() {
      return (this.isEditing === true) ? this.$t('users.edit.title') : this.$t('users.create.title')
    },
  },

  methods: {
    async storeUser() {
      const valid = await this.$refs.form.validate()

      if (valid) {
        if (!this.isEditing) {
          await this.$store.dispatch('boInvite/storeInvitationCode', this.user)
              .then(resp => {
                console.log('then close dialog resp ', resp)
                this.close()
              })
              .catch(err => {
                console.log('create error err ', err)
              })
        } else {
          // await this.$store.dispatch('page/update', this.user)
          //     .then(resp => {
          //       console.log('then close dialog resp ', resp)
          //       this.close()
          //     })
          //     .catch(err => {
          //       console.log('create error err ', err)
          //     })
        }
      }
    },

    close() {
      this.dialog = false
      this.valid = true
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.isEditing = false
        this.user = clonedeep(this.defaultUser)
      })
    },
  }
}
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

</style>