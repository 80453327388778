<template>
     <v-row>
          <v-col cols="12">
               <v-progress-linear :indeterminate="isLoading"></v-progress-linear>
          </v-col>
          <v-col cols="12" v-if="message">
               <v-alert type="error" v-if="Object.keys(errors).length">
                    {{message}}<br>
                    <ul>
                    <li v-for="(err,field) in errors" :key="field">
                         <b>{{field}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}</span>
                    </li>  
                    </ul>
               </v-alert>
               <v-alert type="primary" v-else>
                    {{message}}
               </v-alert>
          </v-col>
          <v-col cols="12">
                <countries
                    :country.sync="modifier.country"
                    :label="$t('country')"
                    :required-rules="requiredRules"
                ></countries>
          </v-col>
          <v-col cols="12">
               <v-text-field
                    v-model="modifier.description"
                    :label="$t('description')"
                    required
                    outlined
                    :rules="requiredRules"
                    dense
               ></v-text-field>
          </v-col>
          <v-col cols="12">
               <v-text-field
                    type="number"
                    v-model="modifier.count"
                    :label="$t('count')+' (quante volto può essere utilizzato da una singola persona?)'"
                    required
                    outlined
                    :rules="requiredRules"
                    dense
               ></v-text-field>
          </v-col>
          <v-col cols="4">
               <v-select
                     :items="['x','+']"
                     v-model="modifier.type"
                     outlined
                     dense
                     label="type"
                ></v-select>
          </v-col>
          <v-col  cols="8">
                     <v-text-field
                    v-model="modifier.value"
                    :label="$t('value')"
                    required
                    outlined
                    :rules="requiredRules"
                    dense
               ></v-text-field>
          </v-col>
          <v-col cols="12" >
                <v-switch label="has_products" v-model="modifier.has_products" @change="modifier.products=[]"></v-switch>
                <div v-if="modifier.has_products" >
                    <v-list >
                         <v-text-field
                         v-for="(product,i) in modifier.products" :key="i"
                              v-model="product.product_sku_code"
                              :label="$t('product_sku_code')"
                              required
                              outlined
                              :rules="requiredRules"
                               append-icon="mdi-close"
                               @click:append="removeProduct(product)"
                              dense
                         ></v-text-field>
                    </v-list>
                    <v-btn color="info" small @click="addProd" >addProd</v-btn>
                </div> 
          </v-col>
          <v-col cols="6">
               <datepicker
                    id="start"
                    :date.sync="modifier.start"
                    :label="$t('start')"
                    :max="modifier.end"
               ></datepicker>
          </v-col>
          <v-col cols="6">
               <datepicker
                    id="end"
                    :date.sync="modifier.end"
                    :label="$t('end')"
                    :min="modifier.start"
               ></datepicker>
          </v-col>
          <v-col cols="12">
               <v-btn color="primary" :loading="isLoading" block @click="save">SAVE</v-btn>
          </v-col>
          <v-col cols="12">
               <json-debug :objs="[{modifier:modifier}]"></json-debug>
          </v-col>
     </v-row>
</template>

<script>
import Countries from "@/components/form/Countries";
import Datepicker from "@/components/form/Datepicker";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
     components:{
         'countries': Countries,
         'datepicker': Datepicker,
     },
     computed: {
          ...mapGetters({
               isLoading: 'adminBillModifiers/loading',
               errors: 'adminBillModifiers/errors',
               message: 'adminBillModifiers/message',
          }),
     },
     props: {
          modifier:{
               type: Object,
               default: () => ({
                    "description": null,
                    "country": null,
                    "has_products": 0,
                    "products": [],
                    "type": "x",
                    "count": null,
                    "value": null,
                    "start": null,
                    "end": null,
               })
          }
     },
     data: () => ({
          requiredRules: [ v => (v !== '' || !!v) || 'required', ],
     }),
     beforeMount(){
          this.modifier.start = (this.modifier.start) ? moment(this.modifier.start).toISOString().substr(0, 10) : moment().toISOString().substr(0, 10)
          this.modifier.end = (this.modifier.end) ? moment(this.modifier.end).toISOString().substr(0, 10) : moment().add(1,'days').toISOString().substr(0, 10)
     },
     methods:{
          addProd(){
               this.modifier.products.push({'product_sku_code':null});
          },
          removeProduct(item){
               this.modifier.products = this.modifier.products.filter(function(ele){ 
                         return ele != item; 
               });
          },
          async save(){
               if(this.modifier.id){
                    this.$store.dispatch('adminBillModifiers/update',this.modifier)
                    .then((response) => {
                         console.log(response)
                         this.$emit('close')
                    })
               }else{
                    this.$store.dispatch('adminBillModifiers/create',this.modifier)
                    .then((response) => {
                         console.log(response)
                         this.$emit('close')
                    })
               }
          }
     }
}
</script>
