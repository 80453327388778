var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"elevation-0 clickable",attrs:{"loading":_vm.isLoading,"loading-text":_vm.$t('commons.loading'),"headers":_vm.headers,"items":_vm.modifiers,"items-per-page":_vm.meta.per_page,"hide-default-footer":true,"disable-pagination":""},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [((item.is_active))?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-radioactive")]):_vm._e(),(!(item.is_active))?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-radioactive-off")]):_vm._e()]}},{key:"item.has_products",fn:function(ref){
var item = ref.item;
return [((item.has_products))?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e(),(!(item.has_products))?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.type)+" "+_vm._s(item.value))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('DialogFullScreen',{attrs:{"btnIcon":"mdi-pencil","button":_vm.$t('commons.actions.update'),"title":_vm.$t('commons.actions.update')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onClose = ref.onClose;
return _c('FromModifier',{attrs:{"modifier":item},on:{"close":onClose}})}}],null,true)})]}}])}),_c('v-divider'),_c('div',{staticClass:"text-center py-3"},[_c('v-pagination',{attrs:{"length":_vm.meta.last_page,"total-visible":5,"disabled":_vm.isLoading},on:{"input":function($event){return _vm.get()}},model:{value:(_vm.meta.current_page),callback:function ($$v) {_vm.$set(_vm.meta, "current_page", $$v)},expression:"meta.current_page"}})],1),_c('v-card-text',[_c('DialogFullScreen',{attrs:{"button":_vm.$t('commons.actions.new')+' '+_vm.$t('modifier'),"title":_vm.$t('commons.actions.new')+' '+_vm.$t('modifier')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onClose = ref.onClose;
return _c('FromModifier',{attrs:{"new":""},on:{"close":onClose}})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }