<template>
     <v-card>
          <v-data-table
               :loading="isLoading"
               :loading-text="$t('commons.loading')"
               :headers="headers"
               :items="exports"
               :items-per-page="meta.per_page"
               :hide-default-footer="true"
               class="elevation-0 clickable"
               disable-pagination
               :disabled="isLoading"
          >

      <template v-slot:item.title="{ item }">
           <b>{{item.title}}</b><br>
           <small>{{item.description_text}}</small><br>
           <hr>
           <small>{{item.query_text}}</small>
      </template>

      <template v-slot:item.query="{ item }">
           <small>{{item.query_text}}</small>
      </template>

      <template v-slot:item.download="{ item }">
           <v-btn v-if="item.download" color="info" icon :href="item.download" target="_blank">
                <v-icon>mdi-download</v-icon>
           </v-btn>
           <v-btn v-if="!item.download" color="info" icon @click="download(item)" target="_blank" :loading="isLoading">
                <v-icon>mdi-download</v-icon>
           </v-btn>
      </template>

      <template v-slot:item.generated="{ item }">
          <v-icon color="success" v-if="(item.generated)">mdi-check-circle</v-icon>
          <v-icon color="danger" v-if="!(item.generated)">mdi-close-circle</v-icon>
      </template>

      <template v-slot:item.scheduled="{ item }">
          <v-icon color="success" v-if="(item.scheduled)">mdi-check-circle</v-icon>
          <v-icon color="danger" v-if="!(item.scheduled)">mdi-close-circle</v-icon>
      </template>

      <template v-slot:item.saved_inftp="{ item }">
          <v-icon color="success" v-if="(item.saved_inftp)">mdi-check-circle</v-icon>
          <v-btn color="error" icon v-if="!(item.saved_inftp)" @click="saveonftp(item)" :loading="isLoading">
               <v-icon>mdi-content-save</v-icon>
          </v-btn>
      </template>
      
      <template v-slot:item.edit="{ item }">
          <DialogFullScreen btnIcon="mdi-pencil" x-small :button="$t('commons.actions.update')" :title="$t('commons.actions.update')">
               <FormExport :item="item" @close="onClose" slot-scope="{ onClose }" />
          </DialogFullScreen>
      </template>
    </v-data-table>

    <v-divider></v-divider>

     <v-divider></v-divider>
     <v-card-text>
    <div class="text-center py-3">
      <v-pagination
          v-model="meta.current_page"
          :length="meta.last_page"
          :total-visible="5"
          @input="get()"
          :disabled="isLoading"
      ></v-pagination>
    </div>
     </v-card-text>
     <v-divider></v-divider>
     <v-card-actions>
          <DialogFullScreen :button="$t('commons.actions.new')+' '+$t('export')" :title="$t('commons.actions.new')+' '+$t('export')">
               <FormExport @close="onClose" slot-scope="{ onClose }" new />
          </DialogFullScreen>
          <v-spacer></v-spacer>
          <v-btn outlined small color="primary" @click="regenerate('it')" :loading="isLoading">REBUILD -it</v-btn>        
          <v-btn outlined small color="primary" @click="regenerate('uk')" :loading="isLoading">REBUILD -uk</v-btn>        
          <v-btn outlined small color="primary" @click="regenerate('us')" :loading="isLoading">REBUILD -us</v-btn>
     </v-card-actions>
     </v-card>
</template>


<script>
import {mapGetters} from "vuex";
import FormExport from "./FormExport";
import DialogFullScreen from '@/components/commons/DialogFullScreen'
export default {
     components:{FormExport,DialogFullScreen},
     computed: {
          ...mapGetters({
               isLoading: 'exports/loading',
               exports: 'exports/exports',
               export: 'exports/export',
               errors: 'exports/errors',
               meta: 'exports/meta'
          }),
     },
     data: () => ({
          filters: {wheres:[]},
          viewdialog: false,
          headers:[
               {text:'#', value: 'id', sortable: false, width: "5%", align: 'center'},
               {text:'country', value: 'country', sortable: false, width: "5%", align: 'center'},
               {text:'title', value: 'title', sortable: false, width: "55%", align: 'start'},
               {text:'scheduled', value: 'scheduled', sortable: false, width: "5%", align: 'center'},
               {text:'generated', value: 'generated', sortable: false, width: "5%", align: 'center'},
               {text:'saved_inftp', value: 'saved_inftp', sortable: false, width: "5%", align: 'center'},
               {text:'edit', value: 'edit', sortable: false, width: "5%", align: 'center'},
               {text:'download', value: 'download', sortable: false, width: "5%", align: 'center'},
          ],
     }),
     mounted(){
          if(this.exports.length < 1){
               this.get()
          }
     },
     methods:{
          async get(){
               this.$store.dispatch('exports/list',this.filters)
          },
          edit(item){
               console.log(item)
               this.$store.commit('exports/setexport',item)
               this.viewdialog = true
          },
          generate(item){
               console.log('generate',item)
               this.$store.dispatch('exports/generate',item).then((resp)=>{
                         console.log('resp.data',resp.data);
                         item.generated = 1;
               })
          },
          regenerate(country){
               console.log('regenerate',country)
               this.$store.dispatch('exports/regenerate',country).then((resp)=>{
                         console.log('resp.data',resp.data);
                         this.get();
               })
          },
          download(item){
               console.log('downlaod',item)
               if(item.generated){
                    

                    this.$store.dispatch('exports/download',item).then((resp)=>{
                         console.log('resp.data',resp.data);
                         item.download = resp.data.download;
                         window.open(resp.data.download, '_blank').focus();
                    })

               }else{
                    if(confirm("File non generato! generarlo?")){
                         this.generate(item);
                    }
               }

          },
          saveonftp(item){
                 console.log('saveonftp',item)
                 if(item.generated){
                    this.$store.dispatch('exports/saveonftp',item).then((resp)=>{
                         console.log('resp.data',resp.data);
                         item.saved_inftp = 1;
                    })
               }else{
                    if(confirm("File non generato! generarlo?")){
                         this.generate(item);
                    }
               }
          }
          
     }
}
</script>
