<template>
    <div>
      <h3>Inviti</h3>

       <v-data-table
            :loading="loading"
            :loading-text="$t('commons.loading')"
            :headers="headers"
            :items="invites"
            :items-per-page="pagination.per_page"
            :hide-default-footer="true"
            class="elevation-0 clickable border-all"
            disable-pagination
       >

         <template v-slot:item.created_at="{ item }">
           {{ $parseDate(item.created_at) }}
         </template>

         <template v-slot:item.used="{ item }">
           <check
               :val="item.used"
           ></check>
         </template>

        </v-data-table>

      <template v-if="invites.length > 0">
        <v-divider></v-divider>

        <div class="text-center py-3">
            <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                :total-visible="5"
                @input="getList()"
                :disabled="isLoading"
            ></v-pagination>
        </div>
      </template>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Check from "@/components/commons/Check";

    export default {
        name: "BoInviteList",

        data() {
            return {
                headers: [
                  { text: this.$t('commons.fields.id'), value: 'id', sortable: false, width: '5%'},
                  { text: this.$t('users.fields.email'), value: 'email', sortable: false},
                  { text: this.$t('users.fields.scope'), value: 'scope', sortable: false},
                  { text: this.$t('users.fields.store_code'), value: 'store_code', sortable: false},
                  { text: this.$t('users.fields.invite.used'), value: 'used', sortable: false, width: '10%', align: 'center'},
                  { text: this.$t('users.fields.invite.created_at'), value: 'created_at', sortable: false, width: '15%'},
                ]
            }
        },

        components: {
          Check
        },

        mounted() {
            this.getList()
        },

        methods: {
            getList() {
                this.$store.dispatch('boInvite/fetchBoInvites')
            },
        },

        computed: {
          ...mapGetters({
              isLoading: 'boInvite/loadingState',
              invites: 'boInvite/getBoInvites',
              pagination: 'boInvite/getPagination'
          }),

          loading: {
              get() {
                  return this.isLoading
              },
              set(v) {
                  this.$store.dispatch('boInvite/setLoadingStatus', v)
              }
          },
        }
    }
</script>

<style scoped>

</style>
