<template>
  <div>
    <v-data-table
        :loading="isLoading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="items"
        :items-per-page="pagination.per_page"
        :hide-default-footer="true"
        class="elevation-0 clickable"
        disable-pagination
        :expanded.sync="expanded"
        :single-expand="true"
        show-expand
    >

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <vue-json-pretty
              :data="JSON.parse(item.body)"
              :deep="3"
              :showLength="true"
          >
          </vue-json-pretty>
        </td>
      </template>

    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="5"
          @input="getList()"
          :disabled="isLoading"
      ></v-pagination>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VueJsonPretty from 'vue-json-pretty';

export default {
  name: "List",

  data() {
    return {
      expanded: [],
      headers: [
        {text: 'CRM ID', value: 'crm_id', sortable: false, width: '12%'},
        {text: 'Method', value: 'method', sortable: false, width: '8%'},
        {text: 'Uri', value: 'uri', sortable: false},
        {text: 'Status', value: 'response_status', sortable: false, width: '8%'},
        {text: 'Response', value: 'response_body', sortable: false, width: '20%'},
        {text: 'Data', value: 'data', sortable: false, width: '18%'},
        {text: '', value: 'data-table-expand'},
      ]
    }
  },

  components: {
    VueJsonPretty
  },

  mounted() {
    this.getList()
  },

  methods: {
    getList() {
      this.$store.dispatch('apiFail/fetchList')
    },
  },

  computed: {
    ...mapGetters({
      isLoading: 'apiFail/loadingState',
      items: 'apiFail/getList',
      pagination: 'apiFail/getPagination'
    }),
  }
}
</script>

<style scoped>

</style>
