<template>
     <v-row>
          <v-col cols="12">
               <h1>receiptsModifier</h1>
          </v-col>
          <v-col cols="12">
               <ListModifiers/>
          </v-col>
     </v-row>
</template>

<script>
import ListModifiers from "@/components/receiptsModifier/List"
export default {
     components:{
          ListModifiers
     }
}
</script>