<template>
  <v-main>
    <v-container
        class="fill-height"
        fluid

    >
      <v-row
          align="center"
          justify="center"
      >

        <v-col
            cols="12"
            xl="3"
            lg="4"
            sm="6"
            md="6"
        >

          <v-card
              flat
              class="py-15 elevation-0"
          >

            <v-row>
              <v-col class="text-center"
              >
                <img src="../assets/logo.svg" width="50%" alt="" class="mb-10">
                <h2 class="font-weight-medium">Attivazione nuovo account</h2>
                <p class="font-weight-light pb-0" v-if="validToken && !completed">Completa la form per creare il nuovo account</p>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-form v-if="validToken && !completed"
                    ref="registrationForm"
                    v-model="valid"
                    lazy-validation
                    @submit="register"
            >
              <v-row>
                <v-col class="px-15"
                >
                  <v-alert
                      v-if="error"
                      outlined
                      type="error"
                      prominent
                  >
                    {{ error }}
                  </v-alert>

                  <v-text-field
                      :label="$t('users.fields.email')"
                      v-model="user.email"
                      required
                      :rules="requiredRules"
                  ></v-text-field>

                  <v-text-field
                      :label="$t('users.fields.password')"
                      type="password"
                      v-model="user.password"
                      required
                      :rules="passwordRules"
                  ></v-text-field>

                  <v-text-field
                      :label="$t('users.fields.verify_password')"
                      type="password"
                      v-model="user.verify_password"
                      required
                      :rules="confirmPasswordRules"
                  ></v-text-field>

                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center"
                >
                  <v-btn type="submit" :loading="isLoading" large color="primary">
                    {{ $t('auth.login-form.login-button') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-alert v-else-if="!validToken"
                     color="error"
                     border="bottom"
                     class="text-center text-uppercase font-weight-bold white--text"
            >Token non valido
            </v-alert>

            <div v-else class="text-center my-5">
              <v-alert color="success"
                       border="bottom"
                       dense
                       text
                       class="text-center text-uppercase font-weight-bold white--text"
              >Attivazione account completata
              </v-alert>
              <p class="text-uppercase">Procedi alla <a class="font-weight-bold" href="/login">login</a></p>
            </div>

          </v-card>


        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "Login",

  data() {
    return {
      valid: true,
      completed: false,
      user: {
        email: '',
        password: '',
        verify_password: ''
      },
      requiredRules: [
        v => !!v || 'Il campo è obbligatorio',
      ],
      passwordRules: [
        (value) => !!value || 'Please type password.',
        (value) => (value && value.length >= 6) || 'minimum 6 characters',
      ],
      confirmPasswordRules: [
        (value) => !!value || 'type confirm password',
        (value) =>
            value === this.user.password || 'The password confirmation does not match.',
      ],
      error: '',
    }
  },

  mounted() {
    this.$store.dispatch('boInvite/verifyInviteToken', this.$route.params.token)
  },

  computed: {
    ...mapGetters({
      isLoading: 'user/loadingState',
      validToken: 'boInvite/getIsaValidToken'
    }),
  },

  methods: {
    async register(event) {
      event.preventDefault()

      const valid = await this.$refs.registrationForm.validate()

      // VALIDATION
      if (!valid)
        return false;

      if (valid === true) {
        this.$store.dispatch('user/storeBoUser', {user: this.user, token: this.$route.params.token})
            .then(() => {
              this.completed = true
            })
            .catch(() => {
              this.error = 'Credenziali non valide'
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
